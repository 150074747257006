
import { App_Modal_Relations_show, App_Modal_Relations_hide, App_Modal_Relations_changeValue } from "../actions/Types";

const initialStore = {
	isShow: false,
	isFromTable: false,
	field: null,
	relationValue: '',
	selectedItems: [],
	updatedItems: []
};

export const RelationModalReducer = (store = initialStore, action) => {
	const { type, payload } = action;
	switch (type) {

		case App_Modal_Relations_show: {
			return {
				...store,
				isShow: true,
				isFromTable: payload.isFromTable,
				selectedItems: payload.selectedItems,
				field: payload.field
			}
		}

		case App_Modal_Relations_hide: {
			return {
				...store,
				isShow: false
			}
		}
			
		case App_Modal_Relations_changeValue: {
			return {
				...store,
				updatedItems: payload,
				isShow: false
			}
		}

		default: return store;
	}
};