import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import initReducers from './reducers/index';
import middlewares from './middlewares';

export const history = createBrowserHistory();

// начальный стор
const initialState = {};

// Если установлен браузерный плагин, возвращаем его функцию для связки мидлваров
// иначе функцию редакса
const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

// Применяем мидлвары и асинхронные экшены
const middleware = applyMiddleware(routerMiddleware(history), ...middlewares, thunk);
// Настройка стора
const configureStore = preloadState => createStore(
	initReducers(history),
	preloadState,
	composeEnhancers(middleware)
);
// Создаем стор
const store = configureStore(initialState);

export default store;
