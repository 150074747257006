import React, { useEffect, useState, useRef } from "react";
import { Modal, Label, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { DataGrid, Button } from 'devextreme-react';
import { Scrolling, Selection, Sorting, FilterRow, Column, Lookup, SearchPanel } from 'devextreme-react/data-grid';
import { ValidationMessage } from '../ValidationMessage';
import Guid from 'devextreme/core/guid';
import DataSource from 'devextreme/data/data_source';
import TypesConvertor from '../utils/TypesConvertors';
import * as ValidationHelper from '../utils/ValidationHelper'
import { ActiveUser } from "../ActiveUser";
import useShowMessage from '../../hooks/useShowMessage';
import { useSelector, useDispatch } from "react-redux";
import { RelationModal_Hide, RelationModal_ChangeValue } from "../../store/actions/RelationModalActions";

const rdevToGridColumnType = {
	"SysGUID": "Guid",
	"SysENUM": "number",
	"SysString": "string",
	"SysBoolean": "boolean",
	"SysTimeDate": "datetime",
	"SysDate": "date",
	"SysTime": "datetime",
	"SysNumber": "number",
	"SysInt": "number",
	"SysMoney": "number",
	"SysDecimal": "number",
	"SysFias": "string"
};

export const RelationTableModal = ({ selectionMode = "multiple" }) => {
	
	const dispatch = useDispatch();
	const hideModal = () => dispatch(RelationModal_Hide());
	const onChange = (selectedItems) => dispatch(RelationModal_ChangeValue(selectedItems));

	const sendMessage = useShowMessage();
	const dataGridRef = useRef(null);
	const validationMessageRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [columns, setColumns] = useState([]);

	const { isShow, field, selectedItems, isFromTable } = useSelector(s => s.RelationModal);
	const isMultiple = selectionMode === 'multiple';
	const selectMode = isFromTable ? 'none' : selectionMode;

	useEffect(() => getMetadata(), [field]);

	const getMetadata = () => {
		if (!field)	return;
		fetch(`/metadata/tables/${field.relation.table}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json'
			},
		})
			.then(data => data.json())
			.then(data => {
				const autoLookup = data.fieldGroups.autoList.fields.map(f => f.toLowerCase());
				const relationField = field.relation.displayField ? field.relation.displayField.toLowerCase() : "recname";
				if (!autoLookup.find(s => s === relationField)) {
					autoLookup.push(relationField);
				};
				const modalGridColumns = [];
				autoLookup.map(fieldName => {
					const field = data.fields.find(f => f.name == fieldName.toLowerCase());
					if (field) {
						modalGridColumns.push({
							caption: field.displayName,
							dataField: field.name,
							field: field,
							fieldType: field.type,
							expand: field.type === "SysRelation" ? field.name + 'item' : '',
							...field.type == "SysString" ? { filterOperations: ['contains'] } : null
						});
					}
				});
				setColumns(modalGridColumns);
				setIsLoaded(true);
			})
			.catch(ex => console.warn(ex));
	};

	const buildSortExpression = () => {
		let result = [];
		let sorting = field.relation.sorting;

		if (sorting && sorting.fieldName) {
			result.push({ selector: sorting.fieldName, desc: sorting.direction == 'DESC' ? true : false })
		}
		else {
			let relationDisplayField = field.relation.displayField ? field.relation.displayField : "recname";
			result.push({ selector: relationDisplayField, desc: false });
		}

		return result;
	};

	const getDataSource = () => {
		let url = '/odata/' + field.relation.table;
		const expandStr = columns.filter(c => !!c.expand).map(c => c.expand);
		if (!!expandStr.length)
			url += '?$expand=' + expandStr;
		return new DataSource({
			store: {
				type: 'odata',
				url: url,
				key: 'recid',
				keyType: 'Guid',
				version: 4,
				beforeSend: (e) => e.headers = { "Authorization": `Bearer ${localStorage.getItem('token')}` }
			},
			filter: ['recstate', '=', 1],
			sort: buildSortExpression(),
			paginate: true,
			pageSize: 15
		});
	};

	const onEditorPreparing = (e) => {
		const { dataType, editorOptions, dataField, placeholder } = e;

		if (dataType === "datetime" || dataType === "date") {

			const format = dataType === "date" ? 'дд.мм.гггг (10.12.1988)' : 'дд.мм.гггг, чч:мм (10.12.1988, 04:30)'
			const dateReg = dataType === "date" ? /^\d{1,2}.\d{1,2}.\d{4}$/ : /^\d{1,2}.\d{1,2}.\d{4}, ?\d{1,2}:\d{1,2}$/;
			const parentContainer = e.element.closest('.modal-body')
			const fieldName = !!placeholder ?
				placeholder === "Начало" ? dataField + '_start' : dataField + '_end'
				: dataField;

			editorOptions.onKeyDown = (ev) => {
				// Если есть соседний элемент то это фильтрация по диапазону
				const isBeetWeenEvent = !!ev.element.parentNode.nextSibling
				setTimeout(() => {
					const value = ev.event.srcElement.value
					if (value && !dateReg.test(value)) {
						validationMessageRef.current.setError(ev.element, fieldName, `Некорректный формат даты/времени ${format}`, parentContainer, true, isBeetWeenEvent);
					} else {
						validationMessageRef.current.resetErrors(fieldName);
					}
				});
			}
			e.editorOptions.onValueChanged = (args) => {
				validationMessageRef.current.resetErrors(fieldName);
				e.setValue(args.value);
			}
		}
		else if (dataType === "number") {
			let column = columns.find(f => f.dataField.toLowerCase() === e.dataField.toLowerCase());
			if (column == null)
				throw "Не удалось получить информацию о типе поля";

			if (column.fieldType === "SysDecimal" || column.fieldType === "SysMoney") {
				editorOptions.min = ValidationHelper.RDEV_MIN_DECIMAL;
				editorOptions.max = ValidationHelper.RDEV_MAX_DECIMAL;
				editorOptions.onValueChanged = (args) => {
					// Если значение не равно нулю и оно имеет признак экспоненты
					if (!!args.value && args.value.toString().indexOf('e') !== -1) {
						// без таймаута не работает
						setTimeout(() => {
							// конвертируем экспоненту в удобочитаемый вид
							e.setValue(TypesConvertor.fromExponents(args.value));
						});
					}
					e.setValue(args.value);
				}
			}
			if (column.fieldType === "SysInt" || column.fieldType === "SysNumber") {
				editorOptions.min = ValidationHelper.RDEV_MIN_INTEGER;
				editorOptions.max = ValidationHelper.RDEV_MAX_INTEGER;
			}
		}
	};

	const prepareDisplayValue = (value, field) => {
		if (value) {

			if (field.type === "SysTimeDate" || field.type === "SysTime" || field.type === "SysDate") {
				//приводим к нужной временной зоне
				return TypesConvertor.viewDateFromUTC(value, field.type === "SysTimeDate" || field.type === "SysTime");
			}
			if (field.type === "SysString") {
				// если тип значение поля объект в котором определен метод toISOString
				// значит devextreme преобразовал строку в дату
				if (typeof value === 'object' && value.toISOString !== undefined) {
					// Преобразуем дату обратно в строку
					value = TypesConvertor.viewISOstring(value);
				}
			}
			if (field.type === "SysGuid") {
				value = value.toString();
			}
			if (field.type === 'SysENUM' && !!value) {
				const enumItem = field.enum.find(e => e.id === value);
				if (!!value && !enumItem) {
					console.warn(`Перечисление с id ${value} не описано в проектном файле!`);
				}
				value = !!enumItem ? enumItem.value : '';
			}
			if (field.type === "SysDecimal" || field.type === "SysMoney") {
				value = TypesConvertor.fromExponents(value);
			}
			else if (field.type === "SysRelation") {
				return 
			}
		}
		return value;
	};

	const calculateFilterExpression = (column, operation = '=', value) => {
		const { dataField, field, fieldType } = column;

		if (fieldType === 'SysString') {
			const backslashMatch = /\\/.test(value);
			const operationMatch = operation === "contains" || operation === "notcontains";
			if (backslashMatch && operationMatch) {
				// добавим дополнительный бэкслеш при его наличие в фильтре
				value = value.split("").map((e) => (e === "\\" ? "\\\\" : e)).join("");
			}
		}

		if (operation === "between") {
			if ((!!value[0] || value[0] === 0) && (!!value[1] || value[1] === 0)) {
				if (value[0] > value[1]) {
					onShowMessage(`Ошибка в фильтре поля ${field}. Начальное значение не может быть больше конечного.`, false);
				}
			} else {
				return null
			};
		}

		if (fieldType === "SysTimeDate" || fieldType === "SysTime") {
			value =
				operation === "between"
				? value.map((date) => TypesConvertor.dateOffset(date, true))
				: TypesConvertor.dateOffset(value, true);
		}

		switch (fieldType) {
			case "SysGuid":
				return [dataField, operation, new Guid(value)];
			default:
				return operation === "between"
					? [[dataField, ">=", value[0]], "and", [dataField, "<=", value[1]]]
					: [dataField, operation || 'contains', value];
		}
	};

	const onShowMessage = (message, success = true) => {
		sendMessage(success, "RdevSelectItemsDialog", null, message, null);
	};

	/**
	* Получаем (если разрешено) дефолтное значение для поля с нулевым значением
	* @returns
	*/
	const getDefaultNulledValue = (field) => {
		// список групп для которых разрешен просмотр дефолтного поля
		const viewDefaultLabelForGroups = field.viewDefaultLabelForGroups;
		// если он определен в ПФ
		if (!!viewDefaultLabelForGroups && viewDefaultLabelForGroups.length > 0) {
			// получаем список групп текущего пользователя
			const userGroups = ActiveUser.getActiveUserProfile().groups;
			// если в списке разрешенных групп есть одна из групп текущего пользователя
			for (let i = 0; i < userGroups.length; i++) {
				if (viewDefaultLabelForGroups.includes(userGroups[i].recname)) {
					// вернем дефолтное значение (если оно описано в ПФ)
					return !!field.defaultNulledValueLabel
						? field.defaultNulledValueLabel
						: null;
				}
				return null;
			}
		}

		return !!field.defaultNulledValueLabel
			? field.defaultNulledValueLabel
			: null;
	};

	const prepareRelationDisplayValue = (cellData, field, fieldName) => {
		let { table, isLink, displayField, displayFieldType } = field.relation;
		let { displayValue, data } = cellData;
		const fieldValue = data[fieldName];
		let fieldType = displayField != null ? displayFieldType : 'SysString';

		if (fieldType === 'SysFile')
			isLink = true;

		if (!!fieldValue) {
			if (displayValue !== null || displayValue === false || displayValue === 0) {

				switch (fieldType) {

					case "SysTime":
					case "SysTimeDate":
					case "SysDate":
						displayValue = this.prepareDateDisplayValue(displayValue, fieldType)
						break;

					case "SysGUID":
						displayValue = new Guid(displayValue).toString();
						break;
					// https://rm.egspace.ru/issues/64846

					case "SysMoney":
					case "SysDecimal":
						displayValue = TypesConvertor.fromExponents(displayValue);
						break;

					case "SysString":
						// если тип значение поля объект в котором определен метод toISOString
						// значит devextreme преобразовал строку в дату
						if (typeof displayValue === 'object' && displayValue.toISOString !== undefined) {
							// Преобразуем дату обратно в строку
							displayValue = TypesConvertor.viewISOstring(displayValue);
						}
						break;

					case "SysFile":
						return this.renderLinkFileField(data[field.name + '_rdevfile'], field.relation.table, field, fieldValue, data.recid);

					case "SysRelation":
						displayValue = new Guid(displayValue).toString();
						break;
				}
			}
		}
		else {
			displayValue = getDefaultNulledValue(field.relation);
		}

		// отображаем ссылку если значение поля не равно нулю (исключаем невалидные ссылки)
		if (isLink && !!fieldValue) {
			const link = `\\tables\\${table}\\${fieldValue}`
			return (
				<a
					href={link}
					target='_blank'
					title='Перейти к связанному элементу'
				>
					{ /* Отображаемое значение рано нулю, отобразим иконку */}
					{!!displayValue || displayValue === false || displayValue === 0 ? displayValue.toString() : <i className='dx-icon fa fa-external-link' />}
				</a>
			)
		}
		else {
			return displayValue;
		}
	};

	const prepareColumns = () => {
		return columns.map((column) => {
			const { dataField, caption, field, fieldType } = column;

			if (field.type === "SysRelation")
			{
				return (
					<Column
						key={dataField}
						dataField={dataField}
						caption={caption}
						allowFiltering={true}
						allowSearch={true}
						dataType={rdevToGridColumnType[fieldType]}
						cellRender={cellData => prepareRelationDisplayValue(cellData, field, dataField)}
						calculateFilterExpression={(value, operation) => calculateFilterExpression(column, operation, value)}
						{...(fieldType === 'SysGUID' ? { filterOperations: ["=", "<>"] } : {})}
				>
					<Lookup dataSource={{
						store: {
							type: 'odata',
							url: `/odata/${field.relation.table}`,
							key: 'recid',
							keyType: 'Guid',
							version: 4,
							beforeSend: (e) => e.headers = { "Authorization": `Bearer ${localStorage.getItem('token')}` }
						},
						filter: ['recstate', '=', 1],
						paginate: true,
						pageSize: 15
					}}
						displayExpr={field.relation.displayField}
						valueExpr='recid'
					/>
				</Column>
			);
			}

			return (
				<Column
					key={dataField}
					dataField={dataField}
					caption={caption}
					allowFiltering={true}
					allowSearch={rdevToGridColumnType[fieldType] !== "Guid"}
					dataType={rdevToGridColumnType[fieldType]}
					calculateFilterExpression={(value, operation) => calculateFilterExpression(column, operation, value)}
					calculateDisplayValue={values => prepareDisplayValue(values[dataField], field)}
					{...(fieldType === 'SysGUID' ? { filterOperations: ["=", "<>"] } : {})}
				>
					{
						fieldType === "SysENUM" && 
						<Lookup dataSource={field.enum} displayExpr={'value'} valueExpr={'id'} />
					}
					{
						fieldType === "SysRelation" &&
						<Lookup dataSource={{
								store: {
									type: 'odata',
									url: `/odata/${field.relation.table}`,
									key: 'recid',
									keyType: 'Guid',
									version: 4,
									beforeSend: (e) => e.headers = { "Authorization": `Bearer ${localStorage.getItem('token')}` }
								},
								filter: ['recstate', '=', 1],
								paginate: true,
								pageSize: 15
							}}
							displayExpr={field.relation.displayField}
							valueExpr='recid'
						/>
					}
				</Column>
			);
		});
	};

	const isReadOnly = () => {
		switch (typeof field.readOnly) {
			case "boolean":
				return !!field.readOnly;
			case "string":
				return field.readOnly.toLowerCase() === "true";
		}
	};

	const getSelectionFilter = () => {
		const result = [];
		selectedItems.forEach(i => {
			if (result.length != 0) {
				result.push('or');
			}
			result.push(['recid', '=', new Guid(i)]);
		});

		return result;
	};

	const onSelectHandler = async () => {
		const selected = await dataGridRef.current.instance.getSelectedRowKeys();
		onChange(selected);
	};

	const renderToolbar = () => (
		<div className={"select-dialog-buttons-container"}>
			{
				(!isReadOnly() && !isFromTable) && <Button text="Выбрать" onClick={onSelectHandler} disabled={!isLoaded} />
			}
			<Button text="Закрыть" onClick={() => hideModal()} disabled={!isLoaded} />
		</div>
	);

	const renderContent = () => {
		if (!isLoaded)
			return (<Spinner />);
		else
			return (
				<DataGrid
					ref={r => dataGridRef.current = r}
					className="rdev__table"
					dataSource={getDataSource()}
					height={'500'}
					hoverStateEnabled={false}
					selectionFilter={getSelectionFilter()}
					showRowLines
					onEditorPreparing={onEditorPreparing}
				>
					<SearchPanel
						visible={true}
						width={240}
						placeholder="Поиск..."
					/>
					<Selection mode={selectMode} showCheckBoxesMode={'always'} allowSelectAll={true} deferred={isMultiple} />
					<Scrolling mode='virtual' columnRenderingMode="virtual" />
					<Sorting mode='multiple' />
					<FilterRow visible />
					{prepareColumns()}
				</DataGrid>
			);
	};

	if (isShow)
		return (
			<Modal isOpen={isShow} toggle={hideModal} size="lg">
				<ModalHeader toggle={hideModal}>
					<Label><b>{field.displayName}</b></Label>
				</ModalHeader>
				<ModalBody>
					{renderContent()}
					{renderToolbar()}
				</ModalBody>
				<ValidationMessage ref={ref => validationMessageRef.current = ref} />
			</Modal>
		);
	else return null;
};

