import { request } from './request';

/** Класс описывающий запросы к системе уведомлений  */
class Notification {

	/** Запрос на получение настроек сервиса уведомлений  */
	static getSettings = () => request.get("/notification/getsettings");
	
	/** Запрос на скрытие лога ( включаю историю ) с указанным ИД  */
	static hideLog = (id) => request.post( "/events/hidelog/" + id );

	/** Запрос на скрытие уведомления */
	static hidePopUp = (id) => request.post( "/notification/hidepopup/" + id );

	/** Получения уведомлений  */
	static getNotify = ( showOnlyPersonalityMessage, maxMessageInPull ) => {
		const url = "/notification/getlogs/" + ( showOnlyPersonalityMessage ? "user/" : "all/") + maxMessageInPull;
		// Добавил к запросу параметр с текущим временем для исключения кэширования браузером старых данных
		return request.get( url + "?_t" + Date.now() );
	};

};

export default Notification;
