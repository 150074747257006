export const SET_TROBER = '@@main/SET_TROBER';
export const SEND_MESSAGE = '@@main/SEND_MESSAGE';

/**
 * Устанавливает/снимает троббер загрузки
 * @param {boolean} value Значение троббера
 * true - троббер установлен, иначе - false 
 */
export const setTrober = (value) => ({
    type: SET_TROBER,
    payload: {
        isWaiting: value
    }
});

/**
 * Отправляет сообщение в уведомления и логи
 * @param {boolean} isSuccess Успешно
 * @param {string} sender Инициализатор уведомление
 * @param {Guid} senderID ID инициализатора
 * @param {string} message Сообщение
 * @param {Any} data Дополнительные данные для отладки
 */
export const sendMessage = (isSuccess, sender, senderID, message, data) => ({
    type: SEND_MESSAGE,
    payload: {
        isSuccess,
        sender,
        senderID,
        message,
        data
    }
});