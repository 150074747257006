import { ATTACHED_FILES_MODAL_SHOW, ATTACHED_FILES_MODAL_HIDE } from "../actions/AttatchedFilesModalActions";

const initialStore = {
	isShow: false,
	fileInfos: [],
	table: '',
	relTable: '',
	column: '',
	relColumn: '',
	entityId: null,
	relEntityId: null,
	allowDownloadZip: true,
	isCompactView: false,
	readOnly: true,
	visibleSignText: false,
	allowSign: false,
	allowDeleteSign: false,
	allowDelete: false,
	allowMultiple: false,
	allowAdd: false
};

export const AttachedFilesModalReducer = (store = initialStore, action) => {
	const { type, payload } = action;
	switch (type) {

		case ATTACHED_FILES_MODAL_SHOW: {
			return {
				...store,
				...payload,
				isShow: true
			}
		}
			
		case ATTACHED_FILES_MODAL_HIDE: {
			return {
				...store,
				isShow: false
			}
		}

		default: return store;
	}
};