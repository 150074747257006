import React from "react";
import { toast } from 'react-toastify';
import { NotificationMessage } from '../components/NotificationMessage';

export class RdevService {

	/**
	 *  Очистка кэша таблиц
	 * */
	static clearGridStorage() {
		sessionStorage.removeItem('rdevTableState_tableName');
		sessionStorage.removeItem('rdevTableState_prefilter');
		sessionStorage.removeItem('rdevTableState_gridState');
		sessionStorage.removeItem('rdevTableState_relationHeaderFilterItemsDictionary');
		sessionStorage.removeItem('rdevTableState_extendedFilterItemsDictionary');
	}

	/** Функция для очистки данных авторизации */
	static async clearAuthData() {
		return new Promise( function(resolve, reject) {
			try {
				// Вызываем функцию очистки локальной памяти браузера в отдельном микро-потоке,
				// так-как в ИЕ11 эта функция работает не корректно
				setTimeout(() => localStorage.clear());
				setTimeout(() => resolve(), 100);
			}
			catch (e) {
				console.error( "Ошибка доступа к локальной памяти", e);
				reject(e);
			}
		});
	};

	static async setAthData( authData ) {
		return new Promise( function(resolve, reject) {
			try {
				// Вызываем функцию очистки локальной памяти браузера в отдельном микро-потоке,
				// так-как в ИЕ11 эта функция работает не корректно
				setTimeout(() => {
					localStorage.setItem('token', authData.token);
					localStorage.setItem('username', authData.username);
					localStorage.setItem('groups', JSON.stringify(authData.groups));
					localStorage.setItem('passwordreset', !!authData.passwordreset);
					localStorage.setItem('profile', JSON.stringify(authData.userProfile));
				});
				setTimeout(() => resolve(true), 100);
			}
			catch (e) {
				console.error( "Ошибка доступа к локальной памяти", e);
				reject(false);
			}
		});
	};

	/**
	 * Функция возвращает объект с переменными среды
	 */
	static async getEnvironmentVariables(unCashedURLParam) {
		const param = !!unCashedURLParam ? unCashedURLParam : "";
		try {
			const result = await fetch("/api/variables" + param, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
			if (result.ok) {
				return await result.json();
			}
			this.writeLog("getEnvironmentVariables", `Запрос к переменным среды вернул следующий результат - ${result.statusText}`, 3);
			return null;
		}
		catch (e) {
			this.writeLog("getEnvironmentVariables", "Не удалось получить переменные среды!", 4, e.message);
			return null;
		}
	}

	/**
	 * Идентификатор для функции createNotify(isSuccess, message, additionData)
	 * */
	static _generateId = () => "id" + Math.random().toString(16).slice(2);
	/**
	 * Создать уведомление
	 * @param {any} isSuccess
	 * @param {any} message
	 * @param {any} additionData
	 */
	static async createNotify(isSuccess, message, additionData) {
		const toastId = this._generateId();
		const toastOptions = {
			className: isSuccess ? "bg-success " : "bg-danger",
			toastId: toastId,
			position: toast.POSITION.BOTTOM_RIGHT,
			autoClose: 5000,
			closeOnClick: false,
			pauseOnFocusLoss: false,
			draggable: false
		};
		const content = <NotificationMessage
			message={message ? message.toString() : ''}
			dateTime={new Date(Date.now())}
			autoClose={5000}
			isSuccess={isSuccess}
			notificationData={additionData}
			toastId={toastId}
		/>;
		const func = isSuccess ? 'success' : 'error';
		toast[func](content, toastOptions);
	}

	/**
	 * Создаем расширенное уведомление
	 * @param {number} type Тип уведомления 
	 * @param {string} message Текстовое сообщение
	 * @param {any} additionData Объект с дополнительными данными
	 * @param {number|boolean} autoCloseTime Сколько показывать уведомления ( 0 - не закрывать )
	 * @param {function} onClose Событие (колбэк) будет вызвано при закрытии уведомления
	 */
	static async createExtendNotify( type, message, additionData, autoCloseTime = 5, onClose = null) {
		const toastId = this._generateId();
		const autoClose = this.getAutoClose(autoCloseTime);
		const isSuccess = type !== 4;
		const toastOptions = {
			toastId: toastId,
			position: toast.POSITION.BOTTOM_RIGHT,
			autoClose,
			closeOnClick: false,
			pauseOnFocusLoss: false,
			draggable: false,
			onClose: onClose
		};
		const content = <NotificationMessage
			message={message ? message.toString() : ''}
			dateTime={new Date(Date.now())}
			autoClose={autoClose}
			isSuccess={isSuccess}
			notificationData={additionData}
			toastId={toastId}
		/>;

		switch( type ) {		
			case 1: {
				toastOptions.className = "bg-success";
				toast.success(content, toastOptions);
				break;
			}
			case 2: {
				toast.info(content, toastOptions);
				break;
			}
			case 3: {
				toast.warning(content, toastOptions);
				break;
			}
			case 4: {
				toastOptions.className = "bg-danger";
				toast.error(content, toastOptions);
				break;
			}
			case 0:
			default: {
				toast(content, toastOptions);
				break;
			}
		}
		return toastId;
	};

	static getAutoClose = (autoClose) => {
		if ( !autoClose ) return false;
		else if ( !!autoClose )
			return autoClose * 1000;
		else 
			return 5000;
	};

	/**
	 * Создать лог, функция прослойка
	 * @param {any} sender
	 * @param {any} senderID
	 * @param {any} text
	 * @param {any} isSuccess
	 * @param {any} notificationData
	 */
	static createLog(sender, senderID, text, isSuccess, notificationData) {
		this.writeLog(sender, senderID, text, isSuccess ? 1 : 4, notificationData);
	}

	/**
	 * Функция делает запись в таблицу логов событий
	 * @param {string} objectName Наименование объект вызвавший событие 
	 * ( по умолчанию - RdevService)
	 * @param {string} message Сообщение
	 * @param {number} logLevel Уровень лога 
	 * 1 - INFO
	 * 2 - DEBUG
	 * 3 - WARNING
	 * 4 - ERROR
	 * @param {any} data Дополнительные данные
	 */
	static writeLog(objectName = 'RdevService', senderID = '-', message, logLevel, data) {

		switch (logLevel) {
			case 1:
			case 2:
				console.log(message, data);
				break;
			case 3:
				console.warn(message, data);
				break
			case 4:
				console.error(message, data);
				break;
			default:
				console.log(message, data);
				break;
		}

		const body = {
			objectname: objectName,
			objectid: senderID,
			message: message ? message.toString() : '',
			data: !!data ? JSON.stringify(data) : "",
			eventtype: logLevel,
			closed: false
		};
		const req = {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json;odata=verbose'
			},
			method: 'POST',
			body: JSON.stringify(body)
		};

		fetch("/events/createlog", req)
	}
}
