import { App_Project_CheckPlugins, App_Project_Upload, App_Project_ClosePluginModal, App_Project_ShowPluginModal } from '../actions/Types';

const initialStore = {
	needUpdate: false,
	showPluginModal: false,
	pluginsFullInfo: [],
	isPluginsQuestions: false,
	methodName: ''
};

export const ProjectReducer = (store = initialStore, action) => {
	const { payload } = action;
	switch (action.type) {

		case App_Project_CheckPlugins: {
			return {
				...store,
				file: payload.file,
				methodName: payload.methodName
			};
		}
			
		case App_Project_Upload: {
			return {
				...store,
				needUpdate: !store.needUpdate
			};
		}
			
		case App_Project_ShowPluginModal: {
			return {
				...store,
				...payload
			};
		}
			
		case App_Project_ClosePluginModal: {
			return {
				...store,
				showPluginModal: false
			}
		}

		default: return store;
	}
}

