import { SEND_MESSAGE } from "../actions/WaitResult";
import { RdevService } from '../../services/RdevService';

export default store => next => (action) => {
	const { payload } = action;
	switch (action.type) {

		case SEND_MESSAGE: {
			RdevService.createLog(payload.sender, payload.senderID, payload.message, payload.isSuccess, payload.data);
			return next(action);
		}
			
	}

	return next(action)
}
