import React from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Col, Input } from 'reactstrap';

export function NotificationModalWindow(props) {

	// Подготовим поле Data, к выводу
	let dataString;

	if (!!props.data) {
		let data = props.data;
		
		// Если тип данных строковой
		if (typeof (data) === "string") {

			const isJsonString = /^[\],:{}\s]*$/.test(data.replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
			
			// Если строка является строковым представлением JSON
			if (isJsonString) {
				// преобразуем в объект (нужно для применения форматирования)
				data = JSON.parse(props.data);
				// Преобразуем содержимое в строку
				// (с восстановлением форматирования)
				dataString = JSON.stringify(data, null, '\t');
			}
			else
				dataString = data;
		}
		else {
			// Преобразуем содержимое в строку
			// (с восстановлением форматирования)
			dataString = JSON.stringify(data, null, '\t');
		} 
	}

	// Подготовим дату к читаемому формату
	const correctedDate = !!props.date && new Date(props.date).toLocaleString();


	return (
		<Modal isOpen={props.isShow} size="xl">

			<ModalHeader toggle={props.close}  className={props.isSuccess ? "bg-success" : "bg-danger"}>
				{props.isSuccess ? "Успешно" : "Ошибка"}
			</ModalHeader>

			<ModalBody>
				
				<FormGroup row>
					<Label sm={1} className='rdev__notify__column-sm'>Сообщение:</Label>
					<Col sm={11} className='rdev__notify__column-md'>
						<div
							className='form-control rdev__notify__column-message'
							dangerouslySetInnerHTML={{ __html: props.message }}
						/>
					 </Col>
				</FormGroup>

				{
					// если усть данные, то отобразим редактор
					!!dataString &&
					<FormGroup row>
						<Label sm={1} className='rdev__notify__column-sm'>Данные:</Label>
						<Col sm={11} className='rdev__notify__column-md'>
							<Input
								type="textarea"
								readOnly
								value={dataString}
								className='form-control'
								style={{ minHeight: '270px' }}
							/>
						</Col>
					</FormGroup>
				}
				
				<FormGroup row>
					<Label sm={1} className='rdev__notify__column-sm'>Время:</Label>
					<Col sm={11} className='rdev__notify__column-md'>
						<Input type="datetime" value={correctedDate} readOnly />
					</Col>
				</FormGroup>

			</ModalBody>
		</Modal>
	);
}
