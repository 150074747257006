
import { useRef, useLayoutEffect, useEffect, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { NotificationService, RdevService } from '../../services';

/** Контейнер для слежения и отображения уведомлений  */
const NotificationProvider = (props) => {

	const { children } = props;

	const timerRef = useRef(null);
	const [ settings, setSettings ] = useState(null);
	const [ error, setError ] = useState('');
	const [ pull, setPull ] = useState([]);
	const [ visibleRecords, setVisibleRecords ] = useState([]);

	/** Получаем настройки сервиса  */
	useLayoutEffect(() => {
		const getSettings = async () => {
			const result = await NotificationService.getSettings();
			if ( !!result.ok && !!result.response.success ) {
				setSettings( result.response.data );
			}
			else {
				const msg = !!result.response && !!result.response.message ? result.response.message : "Ошибка при получении настроек сервиса уведомлений.";
				setError( msg );
			}
		};
		getSettings();
	}, []);

	/** При появлении ошибок в системе, показываем их в уведомлении  */
	useEffect(() => {
		if ( !!error ) {
			RdevService.createNotify( false, error, settings );
			RdevService.createLog( "NotificationProvider", "", error, false );
		}
	}, [error] );

	/** При получении/изменениях в настройках запускаем сервис */
	useEffect(() => {
		if ( !!settings && !!settings.enable && settings.type === "Client" ) {
			timerRef.current = setInterval( requestData, settings.timeoutCheck );
			requestData();			
		}
		return () => {
			if ( !!timerRef && !!timerRef.current )
				clearInterval( timerRef.current );
		};
	}, [settings]);

	/** Тут следим за пулом сообщений и обрабатываем их  */
	useEffect(() => {
		if ( !!pull && !!pull.length ) {
			pull.filter( p => !visibleRecords.includes(p.recid) )
				.forEach( data => {
					// Генерируем массив с пропсами для уведомления
					const props = [
						data.eventType,// !== 4, // 4 - Ошибка, остальные пока не поддерживаются
						data.message, 
						data.data, 
						!!data.reccode ? +data.reccode : 0 // в этом поле будем хранить время отображения ( 0 - не ограничено )
					];
					// Подпишемся на событие закрытия уведомления
					props.push( () => onClose(data.recid) );
					// Создаем плашку уведомления
					RdevService.createExtendNotify( ...props );
					// Пометим как отображаемое
					setVisibleRecords( prev => ([...prev, data.recid]));
				});
		}
	}, [pull]);

	/** Будет вызвано при закрытии уведомления */
	const onClose = useCallback( (id) => {
		// Если в настройках возведен флаг hideOnClose
		if ( !!settings.hideOnClose ) {
			// Скрываем лог полностью ( включая историю уведомлений )
			NotificationService.hideLog(id);
		} 
		else {
			// Скрываем уведомление
			NotificationService.hidePopUp(id);
		}
	}, [settings]);

	/** Запрос к списку уведомлений */
	const requestData = useCallback( async () => {
		if ( !settings ) return;
		const result = await NotificationService.getNotify( !!settings.showOnlyPersonalityMessage, settings.maxMessageInPull );
		if ( !!result.response && Array.isArray(result.response) && !!result.response.length ) {
			setPull( result.response );
		}
	}, [settings]);

	return children;
};

//Типы пропсов
NotificationProvider.propTypes = {
	children: PropTypes.node
};

export default NotificationProvider;
