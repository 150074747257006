import React from 'react';
import { Modal, ModalBody, Progress } from 'reactstrap';
import connect from "react-redux/es/connect/connect";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Toast
toast.configure();

const TroberInner = (props) => {
	return (
		<Modal backdrop="static" isOpen={props.isWaiting} size="lg">
			<ModalBody>
				  <h3>Пожалуйста, подождите...</h3>
				 <Progress animated value="100" />
			  </ModalBody>
		  </Modal>
	 );
}

const mapStateToProps = ({ TroberReducer }) => ({ isWaiting: TroberReducer.isWaiting });
export const Trober = connect(mapStateToProps, null)(TroberInner);