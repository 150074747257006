import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { TroberReducer } from './WaitResult';
import { OperationModeReducer } from './OperationMode';
import { AttachedFilesModalReducer } from './AttachedFilesModalReducers';
import { ProjectReducer } from './Project';
import { RelationModalReducer } from './RelationModalReducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    TroberReducer,
    OperationModeReducer,
    AttachedFilesModal: AttachedFilesModalReducer,
    Project: ProjectReducer,
    RelationModal: RelationModalReducer
});
