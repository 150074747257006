import { ActiveUser } from '../ActiveUser';

/**
 * Запуск JS вычислений
 * */
export class JSGenerator {

	/**
	 * Выполнить JS скрипт
	 * @param {any} script Текст главного метода
	 * @param {any} data Объект с данными
	 */
	static run(script, data) {
		return this.exec(script, data);
	}

	/**
	 * Получить ссылку
	 * @param {any} link Текст ссылки
	 * @param {any} data Объект с данными
	 */
	static getLink(link, data) {
		// Обернуть текст ссылки в кавычки
		link = '\`' + link + '\`';

		return this.exec(link, data);
	}

	/**
	 * Обработать входящий текст через функцию
	 * @param {any} script Текст главного исполняемого метода
	 * @param {any} data Объект с данными для метода
	 */
	static exec(script, data) {
		try {
			// Получить тело скрипта
			let readyScript = this.getScript(script, data);
			// Создать метод на основе тела
			let func = new Function(readyScript);
			// Вернуть результат выполнения метода
			return func();
		} 
		catch (ex) {
			console.error(`JSGenerator -> exec -> Ошибка при создании/выполнении: ${script} при входящих данных: ${JSON.stringify(data)}`, ex);
			throw ex;
		}
	}

	/**
	 * Вернуть тело скрипта в виде строки
	 * @param {any} script Текст главного исполняемого метода
	 * @param {any} data Объект с данными для метода
	 */
	static getScript(script, data) {
		// Распаковываем данные из входящего пакета
		var extraVariable = (data && data.extraVariable) ? JSON.stringify(data.extraVariable) : 'null';
		var activeRecord = (data && data.activeRecord) ? JSON.stringify(data.activeRecord) : 'null';
		var currentValues = (data && data.currentValues) ? JSON.stringify(data.currentValues) : 'null';

		var environmentVariables = (data && data.environmentVariables) ? JSON.stringify(data.environmentVariables) : 'null';

		var serverExecFunc = (data && data.serverExecFunc) ? JSON.stringify(data.serverExecFunc) : this.getServerExecFunc();
		var activeUser = (data && data.activeUser) ? JSON.stringify(data.activeUser) : ActiveUser.getActiveUserData();
		var activeUserProfile = (data && data.activeUserProfile) ? JSON.stringify(data.activeUserProfile) : ActiveUser.getActiveUserProfile(true);

		// Собираем скрипт
		let jScript = `
				/// Данные о пользователе, опциональны
				// Текущая запись на момент загрузки
				var activeRecord = ${activeRecord};

				// Текущая запись на момент вызова скрипта (на форме)
				var currentValues = ${currentValues};

				// Системные переменные
				var environmentVariables = ${environmentVariables};

				// Дополнительные данные (для расширения)
				var extraVariable = ${extraVariable};

				/// Автоматически получаемые данные, доступны всегда, если специально не переопределены
				// Метод вызова функций на сервере
				var serverExecFunc = ${serverExecFunc};

				// Данные о текущем пользователе
				var activeUser = ${activeUser};

				// Данные профиля текущего пользователя
				var activeUserProfile = ${activeUserProfile};

				`;

		// Добавить текст главного исполняемого метода
		jScript += `
				/// Главный исполняемый метод или строковая переменная
				var result = ${script};
				`;

		// Если переменная хранит метод, вызываем его
		jScript += `
				/// Инициируем вызов, если объявлена функция
				if(typeof result === 'function'){
					result = result();
				}
				`;

		// Вернуть результат выполнения
		jScript += `
				return result;`;

		return jScript;
	}

	/**
	 * Получить текст скрипта для обращения к методу на сервере
	 * */
	static getServerExecFunc() {
		return `
function invoke_server_function(functionName, params) {

	function get_error_object(data) {
		const newData = !!data ? data : null;
		const error_msg = 'Error calling the server function!';
		console.error(error_msg, newData);
		return {
			"success": false,
			"data": newData,
			"message": error_msg
		};
	}

	try {
		let xhr = new XMLHttpRequest();
		xhr.open('POST', '/api/executejs', false);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader('Authorization', 'Bearer ${localStorage.getItem('token')}');
		xhr.send(JSON.stringify({
			'fields': params,
			'method': functionName
		}));
		if (xhr.status === 200) {
			const json_obj = JSON.parse(xhr.responseText);
			return json_obj.result;
		} else {
			return get_error_object(xhr);
		}
	}
	catch(err) {
		return get_error_object(err);
	}
} `;
	}

}


