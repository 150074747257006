import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class LoginCallback extends Component {
	constructor() {
		super();

		this.state = {
			mounted: false,
			backUrl: "/"
		}
	}

	componentDidMount() {
		// Поиск адреса возврата
		if (localStorage['rdevBackUrl']) {
			// Адрес куда надо вернуть пользователя
			var backUrl = localStorage['rdevBackUrl'];
		}

		localStorage.setItem('token', decodeURI(this.props.match.params.token));
		localStorage.setItem('username', decodeURI(this.props.match.params.username).replace("+", " "));

		this.setState({ mounted: true, backUrl: backUrl });
	}

	render() {
		return (
			<div>
				Login callback
				{this.state.mounted && <Redirect to={this.state.backUrl} />}
			</div>
		)
	}
}
