import { App_Modal_Relations_hide, App_Modal_Relations_show, App_Modal_Relations_changeValue } from './Types';
import ManyRelationService from '../../services/ManyRelationService';

/**
 * 
 * @param {*} field Метаданные поля
 * @param {*} values Объект с текущими значениями карточки
 * @param {*} isFromTable Флаг указывающий на открытие из табличного представления
 */
export const RelationModal_Show = (field, values, selectedItems = [], isFromTable = false) => {
	return async dispatch => {

		const relationField = field.relation.relationField.toLowerCase() || "recid";
		const linkField = field.relation.linkField.toLowerCase();
		const relationValue = values[relationField];

		const items = selectedItems;
		if (isFromTable) {
			const filters = `${field.relation.linkParentField} eq ${relationValue}`;
			const result = await ManyRelationService.getLinkTables(field.relation.linkingTable, filters);
			items.push(...(result.response.value.map(r => r[linkField])));
		}

		dispatch({
			type: App_Modal_Relations_show,
			payload: {
				field,
				selectedItems: items,
				isFromTable,
				relationValue
			}
		});
	};
};

/**
 * Экшен для закрытия модального окна
 */
export const RelationModal_Hide = () => ({ type: App_Modal_Relations_hide });

/** Изменения выбранных записей из модального окна */
export const RelationModal_ChangeValue = (selectedItems) => ({
	type: App_Modal_Relations_changeValue,
	payload: selectedItems
});
