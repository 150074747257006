import { request } from './request';

/** Объект с функциями для работы с типом данных "SysManyRelation" (многое ко многим) */
const ManyRelationService = {

	/** Функция для получения данных из указанной таблицы */
	getLinkTables: async (tableName, filter) => {
		let url = '/odata/' + tableName;
		if (filter) {
			url += "?$filter=" + filter;
		}
		return request.get(url);
	},

	/** Функция для внесения изменений в таблицу для связи, в соответствии с информационном объекте */
	applyInfoToLinkTable: async (info) => {
		// Делаем запросы
		return Promise.all([
			...info.needRemoving.map(id => ManyRelationService.removeRelation(info.linkTable, id)),
			...info.needAdding.map(recid => ManyRelationService.addRelation(info.linkTable, {
				[info.ownerLinkField]: info.relationValue,
				[info.externalLinkField]: recid
			}))
		])
			.then(() => {
				return info.count;
			})
			.catch(ex => console.warn(ex)) // тут должна быть обработка ошибок
	},

	/** Функция для создания записи в таблицы для связей  */
	addRelation: async (linkTable, body) => request.post('/odata/' + linkTable, body),

	/** Функция для удаления записи из таблицы для связей  */
	removeRelation: async (linkTable, recid) => request.delete(`/odata/${linkTable}(${recid})`)

}

export default ManyRelationService;
