/** Объект для выполнения запросов */
export const request = {
	get: async (url, headers) => {
		let response = await fetch(url, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			}
		});

		return getResponseData(response);
	},

	post: async (url, data, headers = {}) => {
		let dataResult;

		if ('Content-Type' in headers) {
			dataResult = data;
		} else {
			dataResult = JSON.stringify(data);
		}

		let response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			},
			body: dataResult
		});

		return getResponseData(response);
	},

	delete: async (url, headers) => {
		let response = await fetch(url, {
			method: 'DELETE',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			}
		});

		return getResponseData(response);
	},

	put: async (url, data, headers = {}) => {
		let dataResult;

		if ('Content-Type' in headers) {
			dataResult = data;
		} else {
			dataResult = JSON.stringify(data);
		}

		let response = await fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			},
			body: dataResult
		});

		return getResponseData(response);
	},

	getBlob: async (url, headers) => {
		let response = await fetch(url, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			}
		});

		return response.ok ? response.blob() : {}
	},

	uploadFile: async (url, data, headers = {}) => {
		let response = await fetch(url, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			},
			body: data
		});

		return getResponseData(response);
	},

	getFile: async (url, headers) => {
		let response = await fetch(url, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('token')}`,
				...headers
			}
		});

		return {
			ok: response.ok,
			file: response.ok ? await response.blob() : null,
			fileName: getResponseFileName(response.headers)
		};
	},

	getPromiseAll: async (url, headers) => {
		try {
			let response = await fetch(url, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
					...headers
				}
			});

			if (!response.ok) {
				const error = await getResponseData(response)
				throw new Error(error.text)
			}

			return getResponseData(response);

		} catch (err) {
			throw err
		}
	}
};


/** Получаем данные из ответа */
const getResponseData = async (response) => {
	let dataResult;
	
	const contentType = response.headers.get('content-type');
	if (contentType && contentType.indexOf('application/json') !== -1) {
		dataResult = await response.json();
	}
	else {
		dataResult = await response.text();
	}

	if (!dataResult)
		dataResult = response.statusText;

	return {
		ok: response.ok,
		status: response.status,
		response: dataResult
	};
};

/** Получаем имя скачиваемого файла */
const getResponseFileName = (headers) => {
	const disposition = headers.get('Content-Disposition');
	let fileName = '';

	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);

		if (matches != null && matches[1]) {
			fileName = matches[1].replace(/['"]/g, '');
		}
	}

	return fileName;
};
