import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Callback при ошибке во время авторизации
 * /auth/login-errors-callback/:message
 * :message - сообщение об ошибке
 */
export class LoginErrorsCallback extends Component {
	constructor() {
		super();

		this.state = {
			mounted: false,
			message: "",
			provider: ""
		}
	}

	componentDidMount() {
		this.setState({ mounted: true, provider: decodeURI(this.props.match.params.provider),message: decodeURI(this.props.match.params.message) });
	}

	render() {
		return (
			<div>
				Login errors callback
				{this.state.mounted &&
					<Redirect to={{
						pathname: '/login',
						state: {
							message: this.state.message,
							provider: this.state.provider
						}
					}} />
				}
			</div>
		)
	}
}
