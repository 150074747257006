import { setTrober, sendMessage } from "./WaitResult";

export const CHANGE_OPERATION_MODE = '@@operation_mode/CHANGE_OPERATION_MODE';
export const FETCH_OPERATION_MODE = '@@operation_mode/FETCH_OPERATION_MODE';

export const fetchOperationMode = () => {
	return async dispatch => {
		// TODO: Перенести в сервис
		const resp = await fetch(`/api/operationmode`, {
			method: "GET",
			headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
		});
		const result = await resp.json();
		dispatch({
			type: FETCH_OPERATION_MODE,
			payload: { mode: result.data.operationMode}
		});
	}
};

export const changeOperationMode = (mode) => {
	return async dispatch => {
		dispatch(setTrober(true));
		// TODO: Перенести в сервис
		let isOK;
		fetch(`/api/operationmode`, {
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
			body: JSON.stringify({ operationMode: mode })
		})
			.then(response => {
				isOK = response.ok;
				return response.json();
			})
			.then(data => {
				dispatch(sendMessage(isOK, "OperationMode", null, data.message, data));
				if (isOK) {
					dispatch({
						type: CHANGE_OPERATION_MODE,
						payload: { mode: mode }
					});
				}
				dispatch(setTrober(false));
			})
			.catch(error => {
				dispatch(sendMessage(false, "OperationMode", null, "Не удалось изменить режим работы RDEV.", error));
				dispatch(setTrober(false));
			})
	}
};