import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class ErrorHeader extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="rdev__header_part">
					<div className="rdev__logo" />
					<NavLink
						to="/"
						className="rdev__header__navlnk"
						onClick={() => this.props.removeError()}
					>
						Портал
					</NavLink>
				</div>
			</React.Fragment>
		);
	}
}