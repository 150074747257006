export class ActiveUser {

	static getActiveUserData() {
		if (localStorage.length > 0) {
			return JSON.stringify({
				groups: localStorage.getItem("groups"),
				token: localStorage.getItem("token"),
				username: localStorage.getItem("username")
			});
		}
	}

	/**
	 * Возвращает профиль текущего авторизированного пользователя
	 * @param {boolean} toString при true вернет строку, иначе объект
	 * @returns профиль текущего авторизированного пользователя
	 */
	static getActiveUserProfile(toString) {
		if (localStorage.length > 0) {
			const str = localStorage.getItem("profile");
			return !!toString ? str : JSON.parse(str);
		}
	}

}
