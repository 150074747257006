import { CHANGE_OPERATION_MODE, FETCH_OPERATION_MODE } from '../actions/OperationMode';

const initialStore = {
    currentOperationMode: false
};

export const OperationModeReducer = (store = initialStore, action) => {

    const { payload } = action;

    switch (action.type) {
 
        case CHANGE_OPERATION_MODE:
        case FETCH_OPERATION_MODE: {
            return { currentOperationMode: payload.mode }
        }

        default:
            return store;
    }
}
