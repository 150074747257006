import { SET_TROBER, SEND_MESSAGE } from '../actions/WaitResult';
import { RdevService } from '../../services/RdevService';

const initialStore = {
   isWaiting: false
};

export const TroberReducer = (store = initialStore, action) => {

    const { payload } = action;

    switch (action.type) {

        case SET_TROBER: {
            return { isWaiting: payload.isWaiting }
        }
            
        case SEND_MESSAGE: {
            RdevService.createNotify(payload.isSuccess, payload.message, payload.data );
            return store;
        }
            
        default:
            return store;
    }
}

