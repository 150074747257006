import { request } from './request';

const ProjectService = {

	/**
	 * Получение списка загруженных проектов
	 */
	get: async () => await request.get('/project/getstorage'),

	/**
	 * Создание нового проекта
	 * @param {string} name 
	 * @param {string} type 
	 */
	create: async (name, type) => await request.post(`/project/create/${name}/${type}`),

	/**
	 * Проверка версий плагинов загружаемого проекта
	 * @param {File} file Файл проекта
	 */
	checkPlugin: async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		return await request.uploadFile("/project/checkpluginsversion", formData);
	},

	/**
	 * Удаление проекта
	 * @param {string} projectName Наименование проекта
	 */
	remove: async (projectName) => await request.delete(`/project/delete/${projectName}`),

	/**
	 * Загрузка проекта
	 * @param {File} file Проектный файл
	 */
	uploadLocal: async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		return await request.uploadFile("/project/upload", formData);
	},

	/**
	 *  Загрузка проекта с инициализацией системных таблиц
	 * @param {File} file Проектный файл
	 */
	uploadLocalWithInitTable: async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		return await request.uploadFile("/project2/upload", formData);
	},

	/**
	 * Загрузка проекта в систему
	 * @param {string} name Наименование проекта
	 */
	upload: async (name) => await request.post(`/project/uploadfromstorage/${name}`),

	/**
	 * Проверка версий плагинов загружаемого проекта из удаленного источника
	 * @param {string} fileName 
	 */
	checkPluginsFromUploadedFile: async (fileName) => await request.post(`/project/CheckPluginsVersionFromUploadedFile/${fileName}`),

	/**
	 * Загрузка проекта из удаленного источника
	 * @param {string} fileName Наименование файла
	 */
	UploadFromUploadedFile: async (fileName) => await request.post(`/project/UploadFromUploadedFile/${fileName}`)

};

export default ProjectService;
