import moment from "moment";

export default class TypesConvertor {

	/**
	 * Преобразовывает число с экспоненциальным видом в строку с обычным видом
	 */
	static fromExponents(value) {

		if (value === 0) {
			return 0
		}

		if (!value)
			return null;

		// разбиваем строку на мантиссу и порядок
		var data = String(value).split(/[eE]/);
		// если указана только мантисса, то её и вернем
		if (data.length == 1) return data[0];

		// нули числа
		let zeros = '';
		// знак числа
		const sign = value < 0 ? '-' : '';
		// убираем разделитель из мантиссы
		const mantissa = data[0].replace('.', '');
		// порядок
		let exponent = Number(data[1]) + 1;

		// если экспонента меньше нуля
		if (exponent < 0) {
			// установим начальные символы
			zeros = sign + '0.';
			// добавляем нули согласно экспоненте
			while (exponent++) zeros += '0';
			return zeros + mantissa.replace(/^\-/, '');
		}

		// вычитаем из экспоненты кол-во знаков мантиссы
		exponent -= mantissa.length;
		// добавляем необходимые нули
		while (exponent--) zeros += '0';

		return mantissa + zeros;
	}

	/**
	 * Возвращает дату с текущем смещением
	 */
	static dateFromUTC(value) {
		if (!value)
			return null;
		const date = new Date(value);
		const offset = moment().utcOffset();
		return moment(date).add(offset, 'minutes').toDate();
	}

	/**
	 * Возвращает указанную дату в UTC
	 */
	static dateToUTC(value, withTime) {
		if (!value)
			return null;
		const date = moment(value);

		if (withTime) {
			const dateUTC = new Date(Date.UTC(date.year(), date.month(), date.date(), date.hour(), date.minute(), 0, 0));
			const offset = moment().utcOffset();
			return moment(dateUTC).subtract(offset, 'minutes').toDate();
		}
		else
			return new Date(Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0, 0));
	}

	/**
	 * Возвращает указанную дату с текущем смещением времени, если указан флаг withTime
	 */
	static dateOffset(value, withTime) {
		if (!value)
			return null;
		const date = moment(value);
		const offset = moment().utcOffset();
		const correctedDate = moment(date).subtract(offset, 'minutes').toDate();
		return withTime ? correctedDate : date.toDate();
	}

	/**
	 * Возвращает указанную дату с часовым поясом на указанную дату, если указан флаг withTime
	 */
	static dateOffsetByDate(value, withTime) {
		if (!value)
			return null;
		const date = moment(value);
		const offset = date.utcOffset();
		const correctedDate = moment(date).subtract(offset, 'minutes').toDate();
		return withTime ? correctedDate : date.toDate();
	}

	/**
	 * Возвращает скорректированную относительно текущего смещения дату
	 */
	static dateFromUTCWithCorrectOffset(value) {
		if (!value)
			return null;
		const date = new Date(value);

		const currentOffset = moment().utcOffset();
		const dateOffset = moment(date).utcOffset();

		return moment(date).subtract((dateOffset - currentOffset), 'minutes').toDate();
	}

	/**
	 * Возвращает отформатированную указанную дату 
	 * (для табличного представления)
	 */
	static viewDateFromUTC(value, withTime) {

		if (!value)
			return null;

		if (withTime) {
			const date = new Date(value);
			const offset = moment().utcOffset();
			return moment(date).add(offset, 'minutes').format("DD.MM.YYYY, HH:mm");
		}
		else {
			return moment(value).format("DD.MM.YYYY");
		}
	}

	static viewISOstring(value) {
		if (!value)
			return null;
		// Преобразуем дату обратно в строку
		return value.toISOString()
			.replace('.000Z', '')
			.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/,
				moment(value).format("YYYY-MM-DDThh:mm:ss")
			);
	}

	/**
	 * Форматирование строки даты времени
	 * @param {string} value строка содержащая информацию о дате/времени
	 * @param {boolean} isFull true, если нужна дата и время, false - если только дата
	 * @returns Строка с информацией о дате и/или времени
	 */
	static prepareDateTime = (value, isFull = false) => {
		const date = new Date(value);
		if (!!date) {
			if (isFull) {
				return date.toLocaleDateString() + ', ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			} else {
				return date.toLocaleDateString();
			}
		}
		return value;
	}

	/**
	 * Функция обрабатывает входные данные и приводит их к Boolean типу
	 * ( Требуется в некоторых случаях, когда значение может приходить как в строковом эквиваленте, так и в булевом )
	 * @param {any} val Обрабатываемое значение
	 * @param {Boolean} defaultValue Значение по умолчанию которое будет возвращено в случае неудачной обработки
	 * @returns Boolean значение
	 */
	static booleanParse = (val, defaultValue = false) => {
		if (!val) {
			return false;
		}
		else if (val === true) {
			return true;
		}
		else if (typeof val === 'string') {
			const lcs = val.toLocaleLowerCase();
			if (lcs === 'true') {
				return true;
			}
			else if (lcs === 'false') {
				return false;
			}
			else {
				console.warn('booleanParse - Входная строка не распознана, возвращено значение по умолчанию.');
				return defaultValue;
			}
		}
		console.warn('booleanParse - Неопределенный кейс, возвращено значение по умолчанию.');
		return defaultValue;
	}

	/**
	 * Возвращает строковое выражение сортировок разделенное запятыми (для OData запросов )
	 * @param {Object} field Описание поля
	 * @param {String} defaultSortField Поля для сортировки по умолчанию
	 * @param {String} defaultSortType Тип сортировки по умолчанию. ASC или DESC
	 * @returns Строковое выражение сортировок разделенное запятыми (для OData запросов )
	 */
	static getSortingExpression = (field, defaultSortField, defaultSortType = "DESC") => {
		const sortExpressions = [];
		const sortingList = !!field.sorting ? field.sorting : field.relation.sorting;
		if (!!sortingList && !!sortingList.length) {
			sortingList.forEach(sorting =>
				!!sorting.fieldName && sortExpressions.push(`${sorting.fieldName} ${sorting.direction}`)
			);
		}
		else if (!!defaultSortField) {
			const type = !!defaultSortType && ["asc", "desc"].includes(defaultSortType.toLocaleLowerCase())
				? defaultSortType.toLocaleUpperCase()
				: "DESC";
			sortExpressions.push(`${defaultSortField} ${type}`);
		}
		else
			sortExpressions.push("reccreated DESC");
		
		return !!sortExpressions.length ? sortExpressions.join(',') : sortExpressions;
	};

	/**
	 * Возвращает массив сортировок ( применяется в DataSource'ах )
	 * @param {Object} field Описание поля
	 * @param {String} defaultSortField Поля для сортировки по умолчанию
	 * @param {Boolean} isDesc Тип сортировки по умолчанию. True - DESC, иначе ASC 
	 * @returns Массив сортировок
	 */
	static getSortingExpressionFoGrid = (field, defaultSortField, isDesc = true)  => {
		const result = [];
		const sortingList = !!field.sorting ? field.sorting : field.relation.sorting;
		if (!!sortingList && !!sortingList.length) {
			sortingList.forEach(sorting => {
				if (!!sorting.fieldName) {
					result.push({ selector: sorting.fieldName, desc: sorting.direction.toLocaleLowerCase() === 'desc' ? true : false })
				}
			});
		}
		else if (!!defaultSortField)
			result.push({ selector: defaultSortField, desc: isDesc });
		else
			result.push({ selector: 'reccreated', desc: true });			

		return result;
	};
}
