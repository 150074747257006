import { AppHeader } from "@coreui/react";
import React from "react";
import ErrorHeader from "./ErrorHeader";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, isOpen: false, error: null };
		this.removeError = this.removeError.bind(this);
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		const body = {
			level: "Critical",
			name: "ErrorBoundary",
			message: error.message,
			source: errorInfo.componentStack,
		};

		try {
			fetch(`/odata/rdev___events_log`, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
				},
				method: "POST",
				body: JSON.stringify(body),
			});
		} catch (e) {
			console.error(e);
		}

		try {
			fetch(`/frontendlog/writemessage`, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
				},
				method: "POST",
				body: JSON.stringify(body),
			});
		} catch (e) {
			console.error(e);
		}
	}

	removeError() {
		this.setState({ hasError: false });
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="app">
					<AppHeader className="rdev__header" fixed>
						<ErrorHeader
							className="rdev__header"
							removeError={this.removeError}
						/>
					</AppHeader>
					<div className="app-body">
						<div className="error-body-message">
							<h2>
								В ходе работы произошла критическая ошибка,
								попробуйте выполнить действие повторно. Если
								ошибка повторится, обратитесь к администрации
								портала.
							</h2>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}
