import React, { Component } from 'react';

export class ValidationMessage extends Component {

	constructor() {
		super();

		this.state = {
			errorList: []
		};
	}

	/**
	 * Добавляем алерт
	 * @param {*} element Элемент ввода данных, на который вешаем аллерт
	 * @param {*} field Имя поля
	 * @param {*} message Сообщение
	 * @param {*} parentElement Родительский элемент
	 */
	setError(element, field, message, parentElement, isHighloaded = false, isBetWeenEvent = false) {
		this.addError(element, field, message, false, parentElement, isHighloaded, isBetWeenEvent);
	}

	/**
	 * Добавляем алерт
	 * @param {*} element Элемент ввода данных, на который вешаем аллерт
	 * @param {*} field Имя поля
	 * @param {*} message Сообщение
	 * @param {*} isClear При True - очистит предыдущие аллерты
	 * @param {*} parentElement Родительский элемент
	 * @returns 
	 */
	addError(element, field, message, isClear, parentElement, isHighloaded, isBetWeenEvent) {

		if (!element) return;
		let oldList = this.state.errorList;
		if (isClear) oldList = [];

		const position = this.offset(element, parentElement, isHighloaded, isBetWeenEvent);
		const index = oldList.findIndex(i => i.field === field);

		if (index === -1) {
			element.addEventListener('focusin', (e) => {
				let oldList = this.state.errorList;
				const index = oldList.findIndex(i => i.field === field);
				if (index !== -1) {
					if (e.target.value) {
						oldList[index].show = true;
						this.setState({ errorList: oldList });
					}
					else
						oldList.splice(index, 1);
				}
			});
			element.addEventListener('focusout', () => {
				let oldList = this.state.errorList;
				const index = oldList.findIndex(i => i.field === field);
				if (index !== -1) {
					oldList[index].show = false;
					this.setState({ errorList: oldList });
				}
			});
			oldList.push({ element, field, position, message, show: true });
		}
		else if (oldList[index].position != position) {
			oldList[index].position = position;
			oldList[index].show = true;
		}
		else
			return;

		this.setState({ errorList: oldList });
	}

	/**
	 * Снимает аллерт
	 * @param {*} field Имя поля аллерта. При значении null, очистит все.
	 */
	resetErrors(field) {
		let oldList = this.state.errorList;

		if (!!field) {
			const index = oldList.findIndex(i => i.field === field);
			if (index !== -1) oldList.splice(index, 1);
		}
		else
			oldList = [];

		this.setState({ errorList: oldList });
	}

	/**
	 * Возвращает глобальное смещение элемента
	 * @param {*} element Элемент
	 * @returns Объект с полями left и Top
	 */
	getGlobalOffset(element) {

		let offset = {
			top: 0,
			left: 0
		};

		while (!!element) {
			offset.top += parseInt(element.offsetTop);
			offset.left += parseInt(element.offsetLeft);
			element = element.offsetParent;
		}

		return offset;
	}

	/**
	 * Возвращает смещение элемента относительно карточки с таблицей
	 * @param {*} element Элемент ввода данных
	 * @param {*} parentElement Родительский элемент
	 * @returns Координаты смещения относительно карточки таблицы
	 */
	getOffset(element, parentElement) {
		// Получаем размеры элемента
		const rect = element.getBoundingClientRect();
		let offset = {
			top: 0,
			left: 0,
			height: rect.height,
			width: rect.width
		};

		// Зацикливаемся если элемент не нулевой и не содержит класс card
		while (!!element && !element.classList.contains('card')) {
			// прибавляем смещение элемента
			offset.top += parseInt(element.offsetTop);
			offset.left += parseInt(element.offsetLeft);
			// переключаемся на родительский элемент
			element = element.offsetParent;

			// если элемент из overlay
			if (element.classList.contains('dx-datagrid-filter-range-overlay')) {
				// Получаем из стилей его смещение и парсим его
				const matches = element.style.transform.match(/translate\((\d+)px,[ ](\d+)px\)/);
				if (!!matches && matches.length > 0) {
					offset.left = Number(matches[1]);
					offset.top = Number(matches[2]);
				}
				// Получаем глобальное смещение родительского элемента
				const go = this.getGlobalOffset(parentElement.offsetParent);
				// вычитаем глобальное смещение
				offset.left -= go.left;
				offset.top -= go.top;
				// прибавим высоту элемента инпута
				offset.top += offset.height;
				return offset;
			}
		}

		return offset;
	}

	offset(el, parentElement, isHighlighted, isBetWeenEvent) {
		if (isHighlighted) {
			const parentContainer = parentElement.getBoundingClientRect();
			const elContainer = el.getBoundingClientRect();
			return {
				// Если фильтр диапозона опустим сообщение на инпут ниже
				top: elContainer.top - parentContainer.top + el.offsetHeight + 10 + (isBetWeenEvent ? el.offsetHeight + 1 : 0),
				left: elContainer.left - parentContainer.left,
				width: elContainer.width,
			};
		}

		var rect = this.getOffset(el, parentElement);
		return {
			// опустим алерт чуть ниже элемента
			top: rect.top + rect.height + 10,
			left: rect.left
		}
	}

	renderItem(item) {
		const { field, message, position, show } = item;
		return show && <div key={field} style={position} className="validateFilterMessage">{message}</div>;
	}

	render() {
		const { errorList } = this.state;
		return (
			<div>
				{!!errorList && !!errorList.length
					? errorList.map(item => this.renderItem(item))
					: null
				}
			</div>);
	}
}
