
export const ATTACHED_FILES_MODAL_SHOW = "@@APP/ATTACHED_FILES_MODAL_SHOW";
export const ATTACHED_FILES_MODAL_HIDE = "@@APP/ATTACHED_FILES_MODAL_HIDE";

/**
 * Открывает модальное окно для отображения приложенных файлов
 * @param {Array} filesInfos Массив с описанием файлов
 * @param {string} table Наименование основной таблицы к который привязаны файлы
 * @param {string} relTable Наименование таблицы содержащей ссылочное поле
 * @param {Object} field Объект-описание ссылающегося поля
 * @param {Guid} entityId ID основной сущности к которой привязаны файлы
 * @param {Guid} relEntityId ID сущности которая ссылается на файловое поле
 */
export const AttachedFilesModal_Show = (fileInfos, table = '', relTable = '', field, entityId = null, relEntityId = null ) => ({
    type: ATTACHED_FILES_MODAL_SHOW,
    payload: {
        fileInfos,
        table,
        column: field.relation.displayField,
        entityId,
        relTable,
        relColumn: field.name,
        relEntityId,
        readOnly: field.readOnly,
        allowDownloadZip: field.allowDownloadZip,
        visibleSignText: field.visibleSignText,
        allowSign: field.allowSign,
        allowDeleteSign: field.allowDeleteSign,
        allowDelete: field.allowDelete,
        allowMultiple: field.allowMultiple,
        allowAdd: field.allowAdd
    }
});

/**
 * Экшен для закрытия модального окна
 */
export const AttachedFilesModal_Hide = () => ({ type: ATTACHED_FILES_MODAL_HIDE });

