import React, { Component } from "react";
import { NotificationModalWindow } from "./NotificationModalWindow";
import { toast } from "react-toastify";

export class NotificationMessage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isMessageShow: false,
		};
		this.toggleMessageShow = this.toggleMessageShow.bind(this);
	}

	toggleMessageShow() {
		const { isMessageShow } = this.state;
		const { toastId } = this.props;

		this.setState({ isMessageShow: !isMessageShow });
		// если закроется toast закроется и наша модалка, отключем автозакрытие на время открытие модалки
		toast.update(toastId, {
			autoClose: !isMessageShow ? false : this.props.autoClose,
		});
	}

	render() {
		const { isMessageShow } = this.state;
		const { message, isSuccess, notificationData, dateTime } = this.props;

		// отформатируем текст для более читабельного вида
		const textMessage = !!message
			? message.toString().replace(/,/g, ", ").replace(/":/g, '": ')
			: "";

		return (
			<>

				<div
					style={{ width: "280px"}}
					className='rdev__notify__listitem-text'
					dangerouslySetInnerHTML={{ __html: textMessage }}
					onClick={() => this.toggleMessageShow()}>
				</div>

				<NotificationModalWindow
					close={this.toggleMessageShow}
					isShow={isMessageShow}
					isSuccess={isSuccess}
					message={textMessage}
					data={notificationData}
					date={dateTime}
				/>
			</>
		);
	}
}
