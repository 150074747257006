import moment from "moment";
import { JSGenerator } from './JSGenerator';

/** Определим константы с максимально/минимальными значениями типов */
export const RDEV_MAX_INTEGER = 2147483647;
export const RDEV_MIN_INTEGER = -2147483648;
export const RDEV_MAX_DECIMAL_CHARS = 15;

export const RDEV_MAX_DECIMAL = 999999999999999;
export const RDEV_MIN_DECIMAL = -999999999999999;

/** Класс для создания схемы валидации */
export class ValidationHelper {

	/**
	 * Проверка на максимально/минимальное допустимые значения для указанного типа
	 * @param {?string} error Текст ошибки
	 * @param {*} value Вводимое значение
	 * @param {*} type Тип
	 * @returns Текст ошибки
	 */
	static checkRangeNumericType(error, value, type) {

		if (!value || value === 0)
			return error;

		switch (type) {
			case "SysNumber":
			case "SysInt":
				return this.checkRangeNumberType(error, value);
			case "SysDecimal":
			case "SysMoney":
				return this.checkRangeDecimalType(error, value);

			default:
				return error;
		}
	}

	/**
	 * Проверка на максимально/минимальное допустимые значения для SysInt/SysNumber
	 * @param {string} error Текст ошибки
	 * @param {any} value Вводимое значение
	 * @returns Текст ошибки
	*/
	static checkRangeNumberType(error, value) {
		if (Number(value) > RDEV_MAX_INTEGER)
			error = `Максимально допустимое значение для данного типа: ${RDEV_MAX_INTEGER}!`
		else if (Number(value) < RDEV_MIN_INTEGER)
			error = `Минимально допустимое значение для данного типа: ${RDEV_MIN_INTEGER}!`

		return error;
	}

	/**
	 * Проверка на максимально/минимальное допустимые значения для SysDecimal
	 * @param {string} error Текст ошибки
	 * @param {any} value Вводимое значение
	 * @returns Текст ошибки
	 */
	static checkRangeDecimalType(error, value) {

		if (Number(value) > RDEV_MAX_DECIMAL)
			error = `Максимально допустимое значение для данного типа: ${RDEV_MAX_DECIMAL}!`
		else if (Number(value) < RDEV_MIN_DECIMAL)
			error = `Минимально допустимое значение для данного типа: ${RDEV_MIN_DECIMAL}!`
		else if (value.toString().replace(/-|\+|\.|\,/g, '').length > RDEV_MAX_DECIMAL_CHARS)
			error = `Максимально допустимая разрядность числа для данного типа: ${RDEV_MAX_DECIMAL_CHARS} символов!`;

		return error;
	}

	/**
	 * Проверка на корректность формата GUID
	 * @param {string} error Текст ошибки
	 * @param {any} value Вводимое значение
	 */
	static checkGuidFormat(error, value) {
		if (value) {
			if (!new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i').test(value)) {
				error = "Некорректный GUID";
			}
		}

		return error;
	}

	/**
	 * Валидация даты
	 * @param {array} rules validationRules поля
	 * @param {any} value Вводимое значение в поле
	 * @param {string} error Сообщение об ошибке
	 * @param {any} record Текущая запись
	 */
	static getDateRule(rules, value, error, record) {
		if (rules.find(rule => rule.type === "required") != null && !value) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}

		if (moment(value).isValid() && rules.find(rule => rule.type === "dateRange") != null) {
			let dateRange = rules.find(rule => rule.type === "dateRange");

			// проверяем валидацию min/max
			if (dateRange.minDate != null && moment(value).isBefore(dateRange.minDate)) {
				error = dateRange.message ? dateRange.message : `Дата должна быть не раньше ${dateRange.minDate}`;
			}
			if (dateRange.maxDate != null && moment(value).isAfter(dateRange.maxDate)) {
				error = dateRange.message ? dateRange.message : `Дата должна быть не позже ${dateRange.maxDate}`;
			}
			if (dateRange.maxDate != null && dateRange.minDate != null && (moment(value).isBefore(dateRange.minDate) || moment(value).isAfter(dateRange.maxDate))) {
				error = dateRange.message ? dateRange.message : `Дата должна быть не раньше ${dateRange.minDate} и не позже ${dateRange.maxDate}`;
			}

			// если указано поле, дату которого будем использовать для валидации
			if (dateRange.minDateFromField && moment(record[dateRange.minDateFromField]).isValid() && moment(value).isBefore(record[dateRange.minDateFromField])) {
				error = dateRange.message ? dateRange.message : 'Дата должна быть не раньше даты из поля: ' + dateRange.minDateFromField;
			}
			if (dateRange.maxDateFromField && moment(record[dateRange.maxDateFromField]).isValid() && moment(value).isAfter(record[dateRange.maxDateFromField])) {
				error = dateRange.message ? dateRange.message : 'Дата должна быть не позже даты из поля: ' + dateRange.maxDateFromField;
			}
			if (dateRange.minDateFromField && dateRange.maxDateFromField &&
				(moment(record[dateRange.minDateFromField]).isValid() && moment(record[dateRange.maxDateFromField]).isValid()) &&
				(moment(value).isBefore(record[dateRange.minDateFromField]) || moment(value).isAfter(record[dateRange.maxDateFromField]))) {
				error = dateRange.message ? dateRange.message : `Дата должна быть не позже даты в поле ${dateRange.maxDateFromField} и не раньше даты в поле ${dateRange.minDateFromField}`;
			}

			// если используем текущую дату при валидации
			if (dateRange.minDateIsCurrent && moment(value).isBefore()) {
				error = dateRange.message ? dateRange.message : 'Дата должна быть не раньше текущей даты';
			}
			if (dateRange.maxDateIsCurrent && moment(value).isAfter()) {
				error = dateRange.message ? dateRange.message : 'Дата должна быть не позже текущей даты';
			}

		}

		return error
	}

	static getRelationRule(rules, value, error) {
		if (rules.find(rule => rule.type === "required") != null && !value) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}

		return error
	}

	/**
	 * Валидация числового поля
	 * @param {array} rules validationRules поля
	 * @param {any} value Вводимое значение в поле
	 * @param {string} error Сообщение об ошибке
	 */
	static getNumberRule(rules, value, error) {
		if (rules.find(rule => rule.type === "required") != null && !value) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}
		else if (rules.find(rule => rule.type === "pattern") != null && value != null && value !== "" && !(new RegExp(rules.find(rule => rule.type === "pattern").pattern)).test(value)) {
			error = rules.find(rule => rule.type === "pattern").message ?
				rules.find(rule => rule.type === "pattern").message : "Некорректное значение";
		}
		else if (rules.find(rule => rule.type === "negative") != null && value != null && value !== "" && Number(value) >= 0) {
			error = rules.find(rule => rule.type === "negative").message ?
				rules.find(rule => rule.type === "negative").message : "Число должно быть отрицательным";
		}
		else if (rules.find(rule => rule.type === "positive") != null && value != null && value !== "" && Number(value) <= 0) {
			error = rules.find(rule => rule.type === "positive").message ?
				rules.find(rule => rule.type === "positive").message : "Число должно быть положительным";
		}
		else if (rules.find(rule => rule.type === "integer") != null && value != null && value !== "" && !Number.isInteger(Number(value))) {
			error = rules.find(rule => rule.type === "integer").message ?
				rules.find(rule => rule.type === "integer").message : "Число должно быть целым";
		}
		else if (rules.find(rule => rule.type === "numberRange") != null && value != null && value !== "") {
			let numberRange = rules.find(rule => rule.type === "numberRange");

			if (numberRange.min != null && Number(value) < numberRange.min) {
				error = numberRange.message ? numberRange.message : `Число должно быть не меньше ${numberRange.min}`;
			}
			if (numberRange.max != null && Number(value) > numberRange.max) {
				error = numberRange.message ? numberRange.message : `Число должно быть не больше ${numberRange.max}`;
			}
			if (numberRange.max != null && numberRange.min != null && (Number(value) < numberRange.min || Number(value) > numberRange.max)) {
				error = numberRange.message ? numberRange.message : `Число должно быть не меньше ${numberRange.min} и не больше ${numberRange.max}`;
			}
		}

		return error;
	}

	/**
	 * Валидация строкового поля
	 * @param {array} rules validationRules
	 * @param {string} value Вводимое значение в поле
	 * @param {string} error Сообщение об ошибке
	 */
	static getStringRule(rules, value, error) {
		if (rules.find(rule => rule.type === "required") != null && !value) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}
		else if (rules.find(rule => rule.type === "pattern") != null && value != null && value !== "" && !(new RegExp(rules.find(rule => rule.type === "pattern").pattern)).test(value)) {
			error = rules.find(rule => rule.type === "pattern").message ?
				rules.find(rule => rule.type === "pattern").message : "Некорректное значение";
		}
		else if (rules.find(rule => rule.type === "stringLength") != null && value != null && value !== "") {
			let stringLength = rules.find(rule => rule.type === "stringLength");

			if (stringLength.min != null && value.length < stringLength.min) {
				error = stringLength.message ? stringLength.message : `Длина строки должна быть не меньше ${stringLength.min} символов`;
			}
			if (stringLength.max != null && value.length > stringLength.max) {
				error = stringLength.message ? stringLength.message : `Длина строки должна быть не больше ${stringLength.max} символов`;
			}
			if (stringLength.max != null && stringLength.min != null && (value.length < stringLength.min || value.length > stringLength.max)) {
				error = stringLength.message ? stringLength.message : `Длина строки должна быть не меньше ${stringLength.min} и не больше ${stringLength.max} символов`;
			}
		}

		return error;
	}

	static getGuidRule(rules, value, error) {
		if (rules.find(rule => rule.type === "required") != null && !value) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}

		return error;
	}

	static getFileRule(rules, value, error) {
		if (rules.find(rule => rule.type === "required") != null && (!value || value.length == 0)) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}
		// Проверка на подпись файла
		else if ( !!rules.find(rule => rule.type === "signed") && ( !!value || value.length > 0)) {
			const signed = value.find( v => !!v.isVerify );
			if ( !signed ) {
				error = rules.find(rule => rule.type === "signed").message ?
					rules.find(rule => rule.type === "signed").message : "Необходимо подписать файл";
			}
		}
		return error;
	}

	static getFiasRule(rules, error, record, fieldName) {
		let fiasid = record[fieldName + "_fiasid"];

		if (rules.find(rule => rule.type === "required") != null && (!fiasid)) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Необходимо выбрать значение адреса из списка"
		}

		return error;
	}

	static getTimeRule(rules, value, error, record, fieldName) {
		if (rules.find(rule => rule.type === "required") != null && (value == null)) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}

		return error;
	}

	static getEnumRule(rules, value, error, record, fieldName) {
		if (rules.find(rule => rule.type === "required") != null && (value == null)) {
			error = rules.find(rule => rule.type === "required").message ?
				rules.find(rule => rule.type === "required").message : "Поле должно быть заполнено";
		}

		return error;
	}

	static customValidation(rules, record, environmentVariables, currentValues) {
		var customRule = rules.find(rule => rule.type === "custom");
		if (customRule != null) {
			try {
				var validationRes = JSGenerator.run(customRule.validationCallback,
					{
						activeRecord: record,
						environmentVariables: environmentVariables,
						currentValues: currentValues
					});

				if (validationRes == false) {
					return customRule.message ? customRule.message : "Введено неверное значение";
				}
			}
			catch (ex) {
				console.error("Ошибка рассчета правила валидации! ", ex);
			}
		}
		return null;
	}

	/**
	 * Правила валидации в зависимости от типа поля
	 * @param {string} type Тип поля
	 * @param {array} rules validationRules поля
	 * @param {string} error Сообщение об ошибке
	 * @param {any} value Вводимое значение в поле
	 */
	static getFieldValidation(type, rules, error, value, record, fieldName, environmentVariables, currentValues) {
		if (rules.length > 0) {
			switch (type) {
				case "SysMultiLineString":
				case "SysString":
					error = this.getStringRule(rules, value, error);
					break;
				case "SysGUID":
					error = this.getGuidRule(rules, value, error);
					break;
				case "SysDate":
				case "SysTimeDate":
					error = this.getDateRule(rules, value, error, record);
					break;
				case "SysInt":
					error = this.getNumberRule(rules, value, error);
					break;
				case "SysDecimal":
				case "SysNumber":
				case "SysMoney":
					error = this.getNumberRule(rules, value, error);
					break;
				case "SysRelation":
					error = this.getRelationRule(rules, value, error);
					break;
				case "SysFile":
					error = this.getFileRule(rules, value, error);
					break;
				case "SysFias":
					error = this.getFiasRule(rules, error, record, fieldName);
					break;
				case "SysTime":
					error = this.getTimeRule(rules, value, error, record, fieldName);
					break;
				case "SysENUM":
					error = this.getEnumRule(rules, value, error, record, fieldName);
					break;

				default:
					break;
			}

			if (error == null) {
				error = this.customValidation(rules, record, environmentVariables, currentValues);
			}
		}

		return error;
	}

	/**
	 * Валидация поля
	 * @param {any} value Вводимое значение в поле
	 * @param {any} field Поле
	 */
	static validationField(value, field, record, environmentVariables, currentValues) {
		let error = null;
		if (field.validationRules) {
			error = this.getFieldValidation(field.type, field.validationRules, error, value, record, field.name, environmentVariables, currentValues);
		}

		if (field.type === "SysInt" || field.type === "SysNumber")
			error = this.checkRangeNumberType(error, value)

		if (field.type === "SysGUID")
			error = this.checkGuidFormat(error, value)

		return error;
	}

	/**
	 * Проверка на нулевой или пустой объект
	 * @param {Object} obj Объект
	 * @returns True - объект пустой или равен нулю, иначе False
	 */
	static isNullOrEmptyObject = (obj) => {
		if ( obj === null || obj === undefined )
			return true;
		else
			return Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
	};
}
